import React from "react";
import { Helmet } from "react-helmet";

export const Placeholder = () => {
  return (
    <html>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=0.7" />
        <title>tell their stories</title>
      </Helmet>

      <body>
        <div id="placeholder">
            <div class="main">
            <h1>
                tell their <br/>
                stories
            </h1>
            </div>

            <p>
            <div class="bottom-container">
                <div class="subtitle">
                Digitales Mahnmal<br/> zum Leben der<br/> Todesopfer rechter<br/> Gewalt in Deutschland.
                </div>

                <div class="contact">
                Im Aufbau. Möchtet ihr am Mahnmal mitwirken oder über Neuigkeiten zum Projekt informiert werden? Schreibt uns: <a href="mailto:telltheirstories.website@gmail.com?subject=Newsletter">telltheirstories.website@gmail.com</a>
                </div>
            </div>
            </p>
        </div>
        {/* Matomo Image Tracker */}
        <img referrerpolicy="no-referrer-when-downgrade" src="https://coderat.matomo.cloud/matomo.php?idsite=6&amp;rec=1" style={{ border:0 }} alt="" />
        {/* End Matomo */}
      </body>
    </html>
  )
};

export default Placeholder;
